import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableContainer, tableCellClasses, TableRow, Paper, Table, TableHead, TableBody } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ccyFormat } from '../utils/format'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  [`&.${tableCellClasses.body} .less`]: {
    color: 'red'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

function SummaryComponent ({ data, showTotal }) {
  let total = 0

  if (showTotal) {
    total = data.reduce((a, c) => a + c.value, 0)
  }

  return (
    <TableContainer sx={{ mb: 3 }} component={Paper }>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Descripci&oacute;n</StyledTableCell>
            <StyledTableCell align='right'>Valor</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                <span className={row.value < 0 ? 'less' : ''}>{row.description}</span>
              </StyledTableCell>
              <StyledTableCell align='right'>
                <span className={row.value < 0 ? 'less' : ''}>{ccyFormat(row.value)}</span>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {showTotal && (
            <StyledTableRow>
              <StyledTableCell align='right'>
                <span className={total < 0 ? 'less' : ''}>Total</span>
              </StyledTableCell>
              <StyledTableCell align='right'>
                <span className={total < 0 ? 'less' : ''}>{ccyFormat(total)}</span>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

SummaryComponent.propTypes = {
  data: PropTypes.array.isRequired,
  showTotal: PropTypes.bool.isRequired
}

export default SummaryComponent
