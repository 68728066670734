import React from 'react'

import { Chip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const PREFIX = 'PriceComponent'

const classes = {
  root: `${PREFIX}-root`,
  containerChip: `${PREFIX}-containerChip`
}

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    '& > div': {
      display: 'flex',
      '& > div': {
        width: '50%'
      },
      '& > div:first-of-type': {
        padding: theme.spacing(1, 1, 1, 2)
      },
      '& > div:last-child': {
        padding: theme.spacing(1, 2, 1, 1)
      }
    },
    // specific color depending in position
    '& > div:nth-of-type(1) > div:first-of-type': {
      backgroundColor: '#F4E4BA',
      color: '#C59F38',
      '& .MuiChip-root': {
        backgroundColor: '#C59F38',
        color: theme.palette.common.white
      },
      '& > span': {
        fontWeight: 700
      }
    },
    '& > div:nth-of-type(1) > div:last-child': {
      backgroundColor: '#C8DADB',
      color: '#67858A',
      '& .MuiChip-root': {
        backgroundColor: '#67858A',
        color: theme.palette.common.white
      },
      '& > span': {
        fontWeight: 700
      }
    },
    '& > div:nth-of-type(2) > div:first-of-type': {
      backgroundColor: '#E0F9D9',
      color: '#518449',
      '& .MuiChip-root': {
        backgroundColor: '#518449',
        color: theme.palette.common.white
      }
    },
    '& > div:nth-of-type(2) > div:last-child': {
      backgroundColor: '#E1F5F9',
      color: '#5B9DB3',
      '& .MuiChip-root': {
        backgroundColor: '#5B9DB3',
        color: theme.palette.common.white
      }
    }
  },

  [`& .${classes.containerChip}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    '& > span': {
      flex: '0 1 calc(100% - 40px)',
      textAlign: 'right',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    '& > div': {
      flex: '0 0 40px'
    }
  }
}))

function PriceComponent ({ price, price25, price30, pts }) {
  return (
    <Root className={classes.root}>
      <div>
        <div className={classes.containerChip}><Chip size='small' label='P' /><Typography component='span'>{price}</Typography></div>
        <div className={classes.containerChip}><Chip size='small' label='PTS' /><Typography component='span'>{pts}</Typography></div>
      </div>
      <div>
        <div className={classes.containerChip}><Chip size='small' label='30%' /><Typography component='span'>{price30}</Typography></div>
        <div className={classes.containerChip}><Chip size='small' label='25%' /><Typography component='span'>{price25}</Typography></div>
      </div>
    </Root>
  )
}
PriceComponent.propTypes = {
  price: PropTypes.string.isRequired,
  price25: PropTypes.string.isRequired,
  price30: PropTypes.string.isRequired,
  pts: PropTypes.string.isRequired
}

export default PriceComponent
