import React, { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useFeedProvider, feedActions } from '../Contexts/feedProviders'
import * as d3 from 'd3'
import { Dialog, DialogActions, DialogTitle, OutlinedInput, SpeedDial, SpeedDialAction, SpeedDialIcon, Button, DialogContent, DialogContentText, FormControl, Backdrop, CircularProgress } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import LegendToggleOutlinedIcon from '@mui/icons-material/LegendToggleOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  '& > svg': {
    height: '100%',
    width: '100%'
  }
}))

const actions = [
  {
    icon: <AddOutlinedIcon />,
    name: 'New',
    handleClick: (state, dispatch) => {
      dispatch({ type: feedActions.SET_CURRENT_ACTION, payload: feedActions.ADD_ABO })
    }
  },
  {
    icon: <DeleteForeverOutlinedIcon />,
    name: 'Remove',
    handleClick: (state, dispatch) => {
      dispatch({ type: feedActions.SET_CURRENT_ACTION, payload: feedActions.REMOVE_ABO })
    }
  },
  {
    icon: <UploadFileOutlinedIcon />,
    name: 'Upload Structure',
    handleClick: (state, dispatch) => {
      dispatch({ type: feedActions.START_IMPORT })
    }
  },
  {
    icon: <LegendToggleOutlinedIcon />,
    name: 'Change View',
    handleClick: (state, dispatch) => {
      dispatch({ type: feedActions.TOOGLE_VIEW })
    }
  }
]

function FeedComponent ({ updateTitle, updateSearchPlaceHolder, currentValueSearch }) {
  const { state, dispatch } = useFeedProvider()
  const [size, setSize] = useState({ width: 0, height: 0 })
  const svgRef = useRef(null)

  const handleCancelImport = ((dispath) => () => {
    dispatch({ type: feedActions.CANCEL_IMPORT })
  })(dispatch)

  const handleFileChange = ((dispatch) => (e) => {
    dispatch({ type: feedActions.CHANGE_FILE_IMPORT, payload: e.target.files[0] })
  })(dispatch)

  const handleImportFile = (e) => {
    e.preventDefault()
    if (!state.import.file) {
      // TODO: SHow missing file
      return
    }
    dispatch({ type: feedActions.PROCESSING_FILE_IMPORT })
    const reader = new FileReader()
    reader.onload = (eReader) => {
      const dataString = eReader.target.result
      // TODO: move this into a function lib
      const dataStringLines = dataString.split(/\r\n|\n/)

      // headers is in third line
      // const headers = dataStringLines[2].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
      const abos = []
      for (let i = 3; i < dataStringLines.length; i++) {
        const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
        abos.push({
          parent: i === 3 ? null : row[1].replace(/^"|"$/g, ''),
          abo: row[2].replace(/^"|"$/g, ''),
          deep: parseInt(row[0].replace(/^"|"$/g, '')),
          points: parseFloat(row[9].replace(/^"|,|"$/g, '')),
          groupPoints: parseFloat(row[10].replace(/^"|,|"$/g, '')),
          name: row[4].replace(/^"|"$/g, '')
        })
      }
      dispatch({ type: feedActions.LOAD_ABOS, payload: abos })
    }

    reader.readAsText(state.import.file)
  }

  const handleClose = () => {
    dispatch({ type: feedActions.TOOLS_CLOSE })
  }
  const handleOpen = () => {
    dispatch({ type: feedActions.TOOLS_OPEN })
  }

  const handleCircleClick = (c) => {
    console.log(c.data()[0].data.name)
    console.log(c.data()[0].data.shortName)
    dispatch({ type: feedActions.EXECUTE_CURRENT_ACTION, payload: c.data()[0].data.id })
  }

  useEffect(() => {
    updateTitle('Honorarios')
    if (svgRef.current != null) {
      setSize(svgRef.current.parentNode.getBoundingClientRect())
      d3.select(svgRef.current).append('g')
    }
  }, [])

  useEffect(() => {
    const svgContainer = d3.select(svgRef.current)
      .attr('viewBox', [-size.width / 2, -size.height / 2, size.width, size.height])
      .attr('preserveAspectRatio', 'xMidYMid meet')
    const svgEl = svgContainer.select('g')
    svgEl.selectAll('*').remove()
    const zoom = d3.zoom().on('zoom', function (event) {
      const transform = event.transform
      svgEl.attr('transform', transform)
    })

    const lineCenter = svgEl.append('g')
    lineCenter.append('line')
      .attr('x1', -size.width * 2)
      .attr('x2', size.width * 2)
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke-width', '2')
      .attr('stroke', 'black')

    lineCenter.append('line')
      .attr('y1', -size.height * 2)
      .attr('y2', size.height * 2)
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke-width', '2')
      .attr('stroke', 'black')

    svgContainer.call(zoom)

    const scale = d3.scaleLinear().domain([0, 300, 600]).range(['white', 'green', 'darkgreen'])
    const color = d => scale(parseInt(d.data.points))
    const textColor = d => d3.hsl(color(d)).l > 0.5 ? '#000' : '#fff'

    if (state.type === 1) {
      if (!state.firstAbo) {
        return
      }

      state.struct.fx = 0
      state.struct.fy = 0
      const nodes = state.struct.descendants()
      const links = state.struct.links()
      const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(links))
        .force('colide', d3.forceCollide(70))
        .force('charge', d3.forceManyBody().strength(-700))
        // .force('center', d3.forceCenter(0, 0))

      const link = svgEl.append('g')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('stroke-width', d => Math.sqrt(d.value))

      const node = svgEl.append('g')
        .attr('stroke', '#fff')
        .attr('stroke-width', 5)
        .selectAll('circle')
        .data(nodes)
        .join('circle')
        .attr('r', 40)
        .attr('fill', color)
        .on('click', () => {
          handleCircleClick(d3.select(this), state, dispatch)
        })

      const groupPoints = svgEl.append('g')
        .style('font-size', '0.6rem')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .text(d => d.data.groupPoints)
        .attr('text-anchor', 'middle')
        .style('fill', textColor)

      const lvlText = svgEl.append('g')
        .style('font-size', '0.6rem')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .text(d => `${d.data.level}%`)
        .attr('text-anchor', 'middle')
        .style('fill', textColor)

      const shortNameText = svgEl.append('g')
        .style('font-size', '0.6rem')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .text(d => `${d.data.shortName}`)
        .attr('text-anchor', 'middle')
        .style('fill', textColor)

      const circleHandler = svgEl.append('g')
        .style('fill', 'transparent')
        .selectAll('circle')
        .data(nodes)
        .join('circle')
        .attr('r', 45)

        .on('click', function () {
          handleCircleClick(d3.select(this), state, dispatch)
        })

      simulation.on('tick', () => {
        link
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y)

        node
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)

        circleHandler
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)

        groupPoints
          .attr('x', d => d.x)
          .attr('y', d => d.y)

        lvlText
          .attr('x', d => d.x)
          .attr('y', d => d.y + 10)

        shortNameText
          .attr('x', d => d.x)
          .attr('y', d => d.y - 10)
      })
      return () => {
        simulation.stop()
      }
    } else if (state.type === 3) {
      if (state.firstAbo) {
        const root = state.struct.sum(i => i.points).sort((a, b) => b.points - a.points)
        const packLayout = d3.pack().size([size.width, size.height]).padding(10)
        packLayout(root)
        svgEl.append('g')
          .selectAll('circle')
          .data(root.descendants())
          .enter()
          .append('circle')
          .attr('r', d => d.r)
          .attr('cx', function (d) { return d.x })
          .attr('cy', function (d) { return d.y })
          .attr('fill', 'blue')
          .attr('opacity', 0.3)
          .attr('stroke', 'white')
      }
    } else if (state.type === 2) {
      if (state.firstAbo) {
        const root = state.struct
        const dx = 120
        const dy = 120 // size.width / (root.height + 1)
        d3.tree().nodeSize([dx, dy])(root)

        const nodes = root.descendants()

        svgEl.append('g')
          .attr('fill', 'none')
          .attr('stroke', '#555')
          .attr('stroke-opacity', 0.4)
          .attr('stroke-width', 1.5)
          .selectAll('path')
          .data(root.links())
          .join('path')
          .attr('d', d3.linkVertical()
            .x(d => d.x)
            .y(d => d.y))

        svgEl.append('g')
          .selectAll('circle')
          .data(nodes)
          .join('circle')
          .attr('stroke', 'black')
          .attr('stroke-width', '3')
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)
          .attr('fill', color)
          .attr('r', 40)

        svgEl.append('g')
          .style('font-size', '0.6rem')
          .selectAll('text')
          .data(nodes)
          .join('text')
          .text(d => `PG: ${d.data.groupPoints}`)
          .attr('x', d => d.x)
          .attr('y', d => d.y + 10)
          .attr('text-anchor', 'middle')
          .style('fill', textColor)

        svgEl.append('g')
          .style('font-size', '0.6rem')
          .selectAll('text')
          .data(nodes)
          .join('text')
          .text(d => `PP: ${d.data.points}`)
          .attr('x', d => d.x)
          .attr('y', d => d.y)
          .attr('text-anchor', 'middle')
          .style('fill', textColor)

        svgEl.append('g')
          .style('font-size', '0.6rem')
          .selectAll('text')
          .data(nodes)
          .join('text')
          .text(d => `Nivel: ${d.data.level}%`)
          .attr('x', d => d.x)
          .attr('y', d => d.y + 20)
          .attr('text-anchor', 'middle')
          .style('fill', textColor)

        svgEl.append('g')
          .style('font-size', '0.6rem')
          .selectAll('text')
          .data(nodes)
          .join('text')
          .text(d => `${d.data.missing}`)
          .attr('font-weight', 'bold')
          .attr('x', d => d.x)
          .attr('y', d => d.y + 30)
          .attr('text-anchor', 'middle')
          .style('fill', textColor)

        svgEl.append('g')
          .style('font-size', '0.6rem')
          .selectAll('text')
          .data(nodes)
          .join('text')
          .text(d => `${d.data.shortName}`)
          .attr('x', d => d.x)
          .attr('y', d => d.y - 10)
          .attr('text-anchor', 'middle')
          .style('fill', textColor)

        svgEl.append('g')
          .style('fill', 'transparent')
          .selectAll('circle')
          .data(nodes)
          .join('circle')
          .attr('r', 45)
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)
          .on('click', function () {
            handleCircleClick(d3.select(this), state, dispatch)
          })
      }
    }
  }, [svgRef.current, state.lastVersion])

  return (
    <Container>
      <svg ref={svgRef} />
      <SpeedDial
        ariaLabel='Honorarios Actions'
        icon={<SpeedDialIcon icon={<BuildOutlinedIcon />} />}
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        open={state.tools.open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {actions.map((a, i) => (
          <SpeedDialAction key={i} icon={a.icon} tooltipTitle={a.name} onClick={() => a.handleClick(state, dispatch)} />
        ))}
      </SpeedDial>
      <Dialog open={state.import.dialogOpen} onClose={handleCancelImport}>
        <DialogTitle>Importar archivo de Amway csv</DialogTitle>
        <DialogContent>
          <DialogContentText>
            En centro de negocios de amway puedes encontrar el arvhico de CSV para importar aqui
          </DialogContentText>
          <form>
            <FormControl fullWidth>
              <OutlinedInput onChange={handleFileChange} type='file' placeholder='selecciona el archivo' inputProps={{ accept: '.csv' }} />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelImport}>Cancelar</Button>
          <Button onClick={handleImportFile}>Importar</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={state.import.processing} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Container>
  )
}

FeedComponent.propTypes = {
  updateTitle: PropTypes.func.isRequired,
  updateSearchPlaceHolder: PropTypes.func.isRequired,
  currentValueSearch: PropTypes.string.isRequired
}
export default FeedComponent
