import { Button, Card, CardContent, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { cardActions, cardStatus, loadProductIndex, useCartProvider } from '../Contexts/cartProvider'
import CardSearchResultComponent from './CartSearchResultComponent'
import PriceComponent from './PriceComponent'

const PREFIX = 'CardComponent'

const classes = {
  help: `${PREFIX}-help`,
  infoContent: `${PREFIX}-infoContent`
}

const Container = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.help}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2)
    },
    '& .MuiButton-contained': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      marginTop: theme.spacing(2)
    },
    '& .MuiButtonBase-root': {
      textTransform: 'none'
    }
  },

  [`& .${classes.infoContent}`]: {
    '& > *:last-child': {
      marginTop: theme.spacing(2)
    }
  }
}))

function CardComponent ({ updateTitle, updateSearchPlaceHolder, currentValueSearch }) {
  const { state, dispatch } = useCartProvider()
  useEffect(() => {
    updateTitle('Portafolio')
    updateSearchPlaceHolder('Buscar producto...')

    loadProductIndex(dispatch)
  }, [])

  useEffect(() => {
    if (state.status === cardStatus.READY) {
      dispatch({ type: cardActions.FIND_PRODUCT, payload: currentValueSearch })
    }
  }, [currentValueSearch])

  if (state.hasError) {
    return (
      <Container>
        {state.message}
      </Container>
    )
  }

  return (
    <Container>
      <CardSearchResultComponent searchText={currentValueSearch} findProducts={state.searchProducts} />
      <Paper className={classes.help} variant='outlined'>
        <Typography color='inherit' variant='body2'>
          Para nosotros es muy importante tu efectividad en el negocio, te invitamos a que nos des tus opiniones de esta herramienta
        </Typography>
        <Button variant='contained' color='inherit'>
          ¡Quiero ayudar!
        </Button>
      </Paper>
      <Card>
        <CardContent className={classes.infoContent}>
          <Typography variant='body1'>
            Info
          </Typography>
          <Typography variant='h6'>
            Precios Mayo 2021
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            Identifica el tipo de precio, con el c&iacute;rculo de color que lo acompaña.
          </Typography>
          <PriceComponent price='Precio Público' pts='Puntos' price25='Descuento' price30='Descuento' />
        </CardContent>
      </Card>
    </Container>
  )
}
CardComponent.propTypes = {
  updateTitle: PropTypes.func.isRequired,
  updateSearchPlaceHolder: PropTypes.func.isRequired,
  currentValueSearch: PropTypes.string.isRequired
}
export default CardComponent
