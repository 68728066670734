import React from 'react'

import { Card, CardContent, CardHeader, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import PriceComponent from './PriceComponent'
// import AddBoxIcon from '@mui/icons-material/AddBox'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
const PREFIX = 'CartProductComponent'

const classes = {
  root: `${PREFIX}-root`
}

const StyledCard = styled(Card)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginBottom: theme.spacing(2)
  }
}))

// import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
const formatCurrency = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
})

function formatPrice (value) {
  return formatCurrency.format(value)
}

function formatPoints (value) {
  return value.toString()
}

function CartProductComponent ({ product }) {
  return (
    <StyledCard className={classes.root}>
      <CardHeader
        title={product.name} subheader={`${product.id} - ${product.brand}`} action={
          <IconButton color='secondary' size='large'>
            <DoneOutlinedIcon fontSize='large' color='primary' />
          </IconButton>
      }
      />
      <CardContent className={classes.container}>
        <PriceComponent price={formatPrice(product.price)} price25={formatPrice(product.price25)} price30={formatPrice(product.price30)} pts={formatPoints(product.points)} />
      </CardContent>
    </StyledCard>
  )
}

CartProductComponent.propTypes = {
  product: PropTypes.object.isRequired
}

export default CartProductComponent
