import { pdf } from '@react-pdf/renderer'
import React, { Fragment, useState } from 'react'
import { RenderDynamicComponent } from '../utils/dynamicPDFReact'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { saveAs } from 'file-saver'

function ReportSettlementsPdf ({ format, fileName }) {
  const [waiting, setWaiting] = useState(false)

  const handleGeneratePDF = () => {
    const document = RenderDynamicComponent(format)
    setWaiting(true)

    pdf(document)
      .toBlob()
      .then((blob) => {
        saveAs(blob, `${fileName}.pdf`)
      }).catch((e) => {
        console.log(e)
        alert('Un error ocurrio')
      }).finally(() => {
        setWaiting(false)
      })
  }

  if (!format) {
    return (
      <Fragment></Fragment>
    )
  }

  if (waiting) {
    return (
      <div>Generando...</div>
    )
  }

  return (
    <Button onClick={handleGeneratePDF}>Generar PDF</Button>
  )
}

ReportSettlementsPdf.propTypes = {
  format: PropTypes.object,
  fileName: PropTypes.string
}

export default ReportSettlementsPdf
