import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import * as JsSearch from 'js-search'

const CartContext = createContext()

const cardActions = {
  RESET: 'reset',
  SET_PRODUCT_INDEX: 'setProductIndex',
  SET_ERROR: 'setError',
  FIND_PRODUCT: 'findProduct'
}

const cardStatus = {
  NOT_SET: 'notSet',
  READY: 'ready'
}

const initialValues = {
  products: [],
  searchProducts: [],
  productIndex: null,
  hasError: false,
  message: '',
  status: cardStatus.NOT_SET
}

function cartReducer (state, action) {
  switch (action.type) {
    case cardActions.FIND_PRODUCT:
      if (!action.payload || action.payload === '') {
        state.searchProducts = []
      }

      if (action.payload.startsWith('<') || action.payload.startsWith('>')) {
        if (action.payload.length < 2) {
          state.searchProducts = []
        } else {
          const operator = action.payload[0]
          const findByPrice = action.payload[1].toLowerCase() === 'p'

          const number = parseInt(action.payload.substring(findByPrice ? 2 : 1)) || 0
          const property = findByPrice ? 'price' : 'points'

          state.searchProducts = state.products.filter(p => {
            if (operator === '<') {
              return p[property] <= number
            } else {
              return p[property] >= number
            }
          })
          state.searchProducts.sort((a, b) => {
            if (operator === '<') {
              return a[property] - b[property]
            } else {
              return b[property] - a[property]
            }
          })
        }
      } else {
        state.searchProducts = state.productIndex.search(action.payload)
      }

      return {
        ...state,
        searchProducts: state.searchProducts
      }
    case cardActions.SET_ERROR:
      return {
        ...state,
        hasError: action.payload.hasError,
        message: action.payload.message
      }
    case cardActions.SET_PRODUCT_INDEX:
      return {
        ...state,
        productIndex: action.payload.productIndex,
        products: action.payload.products,
        status: cardStatus.READY
      }
    case cardActions.RESET:
      return initialValues
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialValues)
  const cartContextValue = {
    state, dispatch
  }
  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.object
}

const useCartProvider = () => {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCartProvider must be used within a CartProvider')
  }
  return context
}

async function loadProductIndex (dispatch) {
  const version = '20210515'
  try {
    // we are going to load products.json and tags.json
    const responseProducts = await axios.get(`/data/products.json?v=${version}`)
    const responseTags = await axios.get(`/data/tags.json?v=${version}`)
    if (!responseProducts || !responseProducts.data || !responseTags || !responseTags.data) {
      return dispatch({ type: cardActions.SET_ERROR, payload: { hasError: true, message: 'No se pudo cargar los productos' } })
    }

    const products = responseProducts.data.filter(p => p.points)
    products.forEach(p => {
      p.normalized = p.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
    })

    // merge tags
    Object.keys(responseTags.data).forEach((k) => {
      const pIndex = products.findIndex(p => p.id.toString() === k)
      if (pIndex > -1) {
        products[pIndex] = {
          ...products[pIndex],
          ...responseTags.data[k]
        }
      } else {
        console.log(`${k} product no found`)
      }
    })

    const searchIndex = new JsSearch.Search('id')
    searchIndex.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchIndex.addIndex('normalized')
    searchIndex.addIndex('tags')
    searchIndex.addIndex('id')
    searchIndex.addIndex('brand')
    searchIndex.addIndex('category')
    searchIndex.addDocuments(products)

    dispatch({ type: cardActions.SET_PRODUCT_INDEX, payload: { productIndex: searchIndex, products } })
  } catch (error) {
    dispatch({ type: cardActions.SET_ERROR, payload: { hasError: true, message: 'No se pudo cargar los productos' } })
  }
}
export {
  CartProvider,
  useCartProvider,
  loadProductIndex,
  cardActions,
  cardStatus
}
