import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

const FeedContext = createContext()

const feedActions = {
  ADD_ABO: 'add_abo',
  TOOGLE_VIEW: 'toogle_view',
  LOAD_ABOS: 'load_abos',
  REMOVE_ABO: 'remove_abo',
  TOOLS_OPEN: 'tools_open',
  TOOLS_CLOSE: 'tools_close',
  START_IMPORT: 'start_import',
  CANCEL_IMPORT: 'cancel_import',
  CHANGE_FILE_IMPORT: 'change_file_import',
  PROCESSING_FILE_IMPORT: 'processing_file_import',
  SET_CURRENT_ACTION: 'set_current_action',
  EXECUTE_CURRENT_ACTION: 'execute_current_action'
}

const initialValues = {
  nodes: [],
  links: [],
  lastVersion: 1,
  firstAbo: null,
  struct: null,
  type: 2,
  conf: {
    defaultPoints: 300
  },
  currentAction: '',
  tools: {
    open: false
  },
  import: {
    dialogOpen: false,
    processing: false,
    file: null
  }
}

function genereateNewAbo (state) {
  return {
    points: state.conf.defaultPoints,
    groupPoints: state.conf.defaultPoints,
    id: generateId(),
    abo: '',
    downlines: []
  }
}
function generateId () {
  let d = new Date().getTime()// Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0// Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16// random number between 0 and 16
    if (d > 0) { // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else { // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

const nextLevelPoints = {
  0: 300,
  3: 600,
  6: 1200,
  9: 2400,
  12: 4000,
  15: 7000,
  18: 10000,
  21: 15000
}

function calculateMissing (level, points) {
  const nextPoints = nextLevelPoints[level]
  const missing = (nextPoints - points) * 100
  return Math.round(missing) / 100
}

function calculateLevel (points) {
  if (points >= 10000) {
    return 21
  } else if (points >= 7000) {
    return 18
  } else if (points >= 4000) {
    return 15
  } else if (points >= 2400) {
    return 12
  } else if (points >= 1200) {
    return 9
  } else if (points >= 600) {
    return 6
  } else if (points >= 300) {
    return 3
  }
  return 0
}

function shortName (name) {
  if (!name) return 'UKW'

  const sName = name.split(',')
  const newName = sName[1] + ' ' + sName[0]
  return newName.substring(0, 10)
}

function calculateNodes (struct) {
  struct.eachAfter(s => {
    const node = s.data
    node.groupPoints = node.downlines.reduce((a, d) => a + d.groupPoints, node.points)
    node.groupPoints = Math.round(node.groupPoints * 100) / 100
    node.level = calculateLevel(node.groupPoints)
    node.shortName = shortName(node.name)
    node.missing = calculateMissing(node.level, node.groupPoints)
  })
}

function feedReducer (state, action) {
  let currentAction = action.type
  if (currentAction === feedActions.EXECUTE_CURRENT_ACTION) {
    if (state.currentAction !== '') {
      currentAction = state.currentAction
    } else {
      return state
    }
  }
  switch (currentAction) {
    case feedActions.SET_CURRENT_ACTION:
      if (!state.firstAbo && action.payload === feedActions.ADD_ABO) {
        state.firstAbo = genereateNewAbo(state)
        state.struct = d3.hierarchy(state.firstAbo, d => d.downlines)
        calculateNodes(state.struct)
        state.lastVersion = generateId()
      }
      return {
        ...state,
        currentAction: action.payload,
        tools: {
          ...state.tools,
          open: false
        }
      }
    case feedActions.TOOGLE_VIEW:
      return {
        ...state,
        type: state.type === 2 ? 1 : 2,
        lastVersion: generateId()
      }
    case feedActions.LOAD_ABOS:
    {
      action.payload.forEach(p => {
        p.id = generateId()
        p.downlines = []
      })

      const root = d3.stratify().id(d => d.abo).parentId(d => d.parent)(action.payload)
      state.firstAbo = root.data
      state.struct = root
      state.struct.each(d => {
        d.data.downlines = d.children ? d.children.map(child => child.data) : []
        d.data.children = undefined
        delete d.data.children
      })
      calculateNodes(state.struct)
      return {
        ...state,
        lastVersion: generateId(),
        import: {
          ...state.import,
          processing: false,
          dialogOpen: false
        },
        tools: {
          ...state.tools,
          open: false
        },
        currentAction: ''
      }
    }
    case feedActions.PROCESSING_FILE_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          processing: true,
          dialogOpen: false
        }
      }
    case feedActions.CHANGE_FILE_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          file: action.payload
        }
      }
    case feedActions.CANCEL_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          dialogOpen: false,
          file: null,
          processing: false
        }
      }
    case feedActions.START_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          dialogOpen: true
        }
      }
    case feedActions.TOOLS_CLOSE:
      return {
        ...state,
        tools: {
          ...state.tools,
          open: false
        }
      }
    case feedActions.TOOLS_OPEN:
      return {
        ...state,
        tools: {
          ...state.tools,
          open: true
        }
      }
    case feedActions.REMOVE_ABO: {
      const selected = state.struct ? state.struct.find(n => n.data.id === action.payload) : undefined
      // we need to removed all childs and links
      if (selected && selected.parent) {
        selected.parent.data.downlines = selected.parent.data.downlines.filter(d => d.id !== selected.data.id)
      }
      state.struct = d3.hierarchy(state.firstAbo, d => d.downlines)
      calculateNodes(state.struct)
      return {
        ...state,
        lastVersion: generateId()
      }
    }
    case feedActions.ADD_ABO: {
      const selected = state.struct ? state.struct.find(n => n.data.id === action.payload) : undefined
      const newAbo = genereateNewAbo(state)

      if (selected) {
        selected.data.downlines.push(newAbo)
        selected.data.groupPoints += newAbo.points
      } else if (state.firstAbo !== null) {
        state.firstAbo.downlines.push(newAbo)
        state.firstAbo.groupPoints += newAbo.points
      } else {
        state.firstAbo = newAbo
      }

      state.struct = d3.hierarchy(state.firstAbo, d => d.downlines)
      calculateNodes(state.struct)
      return {
        ...state,
        lastVersion: generateId()
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const FeedProvider = ({ children }) => {
  const [state, dispatch] = useReducer(feedReducer, initialValues)
  const feedContextValue = {
    state, dispatch
  }
  return (
    <FeedContext.Provider value={feedContextValue}>
      {children}
    </FeedContext.Provider>
  )
}

FeedProvider.propTypes = {
  children: PropTypes.object
}

const useFeedProvider = () => {
  const context = useContext(FeedContext)
  if (context === undefined) {
    throw new Error('useFeedProvider must be used within a FeedProvider')
  }
  return context
}

export {
  FeedProvider,
  feedActions,
  useFeedProvider
}
