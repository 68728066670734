import React from 'react'
import PropTypes from 'prop-types'
import { Circle, G, Line, Svg, Text } from '@react-pdf/renderer'

function PDFSvg (props) {
  const items = props.root.descendants()
  const links = props.root.links()

  // we need to calculate the size and the window base on
  const YTop = Math.min(...items.map(i => i.y - 60))
  const YBottom = Math.max(...items.map(i => i.y + 60))
  const XLeft = Math.min(...items.map(i => i.x - 60))
  const XRight = Math.max(...items.map(i => i.x + 60))

  const height = Math.abs(YTop - YBottom)
  const width = Math.abs(XLeft - XRight)

  const viewBox = `${XLeft} ${YTop} ${width} ${height}`
  return (
    <Svg width={props.width} height={props.height} preserveAspectRatio='xMidYMid meet' viewBox={viewBox} style={props.style}>
      <G fill='none' stroke='#555' strokeOpacity={0.4} strokeWidth={1.5}>
        {links.map((l, n) => (
          <Line key={n} x1={l.source.x} y1={l.source.y} x2={l.target.x} y2={l.target.y}></Line>
        ))}
      </G>
      <G>
        {items.map((i, n) => (
          <Circle key={n} stroke='black' strokeWidth={3} cx={i.x} cy={i.y} fill={i.data.level === 'D' ? '#b9f2ff' : '#50C878'} r={40}>

          </Circle>
        ))}
      </G>
      <G>
        {items.map((i, n) => (
          <Text key={n} style={{ fontSize: '10px' }} x={i.x} y={i.y} textAnchor='middle' fill={i.data.level === 'D' ? 'black' : 'white'}>{i.data.shortName}</Text>
        ))}
      </G>
      <G>
        {items.map((i, n) => (
          <Text style={{ fontSize: '10px' }} key={n} x={i.x} y={i.y + 10} textAnchor='middle' fill={i.data.level === 'D' ? 'black' : 'white'}>{`${i.data.points}`}</Text>
        ))}
      </G>
    </Svg>
  )
}

PDFSvg.propTypes = {
  root: PropTypes.object.isRequired,
  style: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number
}

export default PDFSvg
