import React from 'react'

import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import CartProductComponent from './CartProductComponent'

const PREFIX = 'CardSearchResultComponent'

const classes = {
  root: `${PREFIX}-root`
}

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginBottom: theme.spacing(2)
  }
}))

function CardSearchResultComponent ({ searchText, findProducts }) {
  if (searchText && searchText.length > 0 && findProducts.length === 0) {
    // products not found
    return (
      <Paper className={classes.notFound}>
        <Typography>
          {`No se encontro ningun producto con: ${searchText}`}
        </Typography>
      </Paper>
    )
  }

  if (!searchText || searchText.length === 0) {
    return (<></>)
  }

  return (
    <Root className={classes.root}>
      {findProducts.map(p => (<CartProductComponent key={p.id} product={p} />))}
    </Root>
  )
}

CardSearchResultComponent.propTypes = {
  findProducts: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired
}

export default CardSearchResultComponent
