import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import { generateId, shortName } from '../utils/helpers'
import * as d3 from 'd3'

const EventContext = createContext()

const eventActions = {
  ADD_ABO: 'add_abo',
  REMOVE_ABO: 'remove_abo',
  LOAD_ABOS: 'load_abos',
  EDIT_ABO: 'edit_abo',
  TOOLS_OPEN: 'tools_open',
  TOOLS_CLOSE: 'tools_close',
  SET_CURRENT_ACTION: 'set_current_action',
  EXECUTE_CURRENT_ACTION: 'execute_current_action',
  EDIT_CANCEL: 'edit_cancel',
  EDIT_SAVE: 'edit_save',
  EDIT_CHANGE: 'edit_change',
  EVENT_CALC_BEGIN: 'event_calc_begin',
  EVENT_CALC_CANCEL: 'event_calc_cancel',
  EVENT_CALC_CHANGE: 'event_calc_change',
  EVENT_CALC_APPLY: 'event_calc_apply',
  EVENT_CALC_SET_PDF: 'event_calc_set_pdf',
  START_IMPORT: 'start_import',
  CANCEL_IMPORT: 'cancel_import',
  CHANGE_FILE_IMPORT: 'change_file_import',
  PROCESSING_FILE_IMPORT: 'processing_file_import',
  CHANGE_RESULT_SELECTED: 'change_result_selected'
}

const initialValues = {
  nodes: [],
  links: [],
  lastVersion: 1,
  firstAbo: null,
  struct: null,
  currentAction: '',
  conf: {
    defaultPoints: 0,
    defaultLevel: 'D',
    calc: {
      diamondTickets: 20,
      emeraldTickets: 10,
      unmeetCondition: {
        firstGeneration: 0.5,
        secondGeneration: 0.3,
        thirdGeneration: 0.2
      },
      creditBag: {
        percentaje: 0.5,
        factor: {
          diamond: 0.5,
          emerald: 0.5
        },
        generationRate: {
          firstGeneration: 1,
          secondGeneration: 0.5,
          thirdGeneration: 0.25
        }
      },
      directBag: {
        percentaje: 0.5,
        factor: {
          diamond: 1 / 4.0,
          emerald: 3 / 4.0
        }
      }
    }
  },
  tools: {
    open: false
  },
  edit: {
    open: false,
    data: {},
    title: 'Editando'
  },
  calc: {
    open: false,
    title: 'Calculos',
    utilities: 0,
    eventName: 'Liquidación',
    totalTickets: 0,
    tmpPdf: null
  },
  import: {
    dialogOpen: false,
    processing: false,
    file: null
  },
  pdfFormat: null
}

function genereateNewAbo (state) {
  return {
    points: state.conf.defaultPoints,
    groupPoints: state.conf.defaultPoints,
    id: generateId(),
    abo: '',
    downlines: [],
    level: state.conf.defaultLevel,
    settlements: []
  }
}

function calculateNodes (struct, bags, config) {
  struct.descendants().forEach(d => {
    d.data.settlements = []
  })

  struct.eachAfter(s => {
    const node = s.data
    node.groupPoints = node.downlines.reduce((a, d) => a + d.groupPoints, node.points)
    node.shortName = shortName(node.name)

    // Calculate diamonds tickets
    const tSumDTickets = node.downlines
      .filter(d => d.level !== 'D')
      .reduce((a, d) => a + (d.tDiamondTickets || 0), node.points)

    if (node.level === 'D') {
      node.dPoints = tSumDTickets
      node.tDiamondTickets = 0
      if (bags && bags.credits) {
        const diamondPayment = node.dPoints * bags.credits.diamondFactorTicket
        node.settlements.push({
          description: `Diamond Credit Bag ${node.dPoints}`,
          value: diamondPayment,
          type: 0
        })
        // TODO: find Uplines diamond to 3 generation
        const myDiamonds = s.ancestors().filter(p => p.data.id !== node.id && p.data.level === 'D')
        if (myDiamonds.length > 0) {
          const nFirstDiamond = myDiamonds[0]
          const firstDiamond = diamondPayment * config.creditBag.generationRate.secondGeneration
          node.settlements.push({
            description: `Diamond Credit Bag ${node.dPoints} for ${nFirstDiamond.data.name} (50%)`,
            value: -firstDiamond,
            type: 0
          })

          nFirstDiamond.data.settlements.push({
            description: `Diamond Credit Bag ${node.dPoints} by ${node.name} (50%)`,
            value: firstDiamond,
            type: 0
          })
        }

        if (myDiamonds.length > 1) {
          const nSecondDiamond = myDiamonds[1]
          const secondDiamond = diamondPayment * config.creditBag.generationRate.thirdGeneration
          node.settlements.push({
            description: `Diamond Credit Bag ${node.dPoints} for ${nSecondDiamond.data.name} (25%)`,
            value: -secondDiamond,
            type: 0
          })

          nSecondDiamond.data.settlements.push({
            description: `Diamond Credit Bag ${node.dPoints} by ${node.name} (25%)`,
            value: secondDiamond,
            type: 0
          })
        }
      }
      if (bags && bags.direct) {
        node.settlements.push({
          description: `Diamond Direct Bag ${node.dPoints}`,
          value: node.dPoints * bags.direct.diamondFactorTicket,
          type: 1
        })
      }
    } else {
      node.dPoints = 0
      node.tDiamondTickets = tSumDTickets
    }
    if (bags && bags.credits) {
      const emeraldPayment = node.points * bags.credits.emeraldFactorTicket
      node.settlements.push({
        description: `Emerald Credit Bag ${node.points}`,
        value: emeraldPayment,
        type: 2
      })
      // TODO: find Uplines emerald to 3 generation
      const myEmerald = s.ancestors().filter(p => p.data.id !== node.id)
      if (myEmerald.length > 0) {
        const nFirstEmerald = myEmerald[0]
        const firstEmerald = emeraldPayment * 0.5
        node.settlements.push({
          description: `Emerald Credit Bag ${node.points} for ${nFirstEmerald.data.name} (50%)`,
          value: -firstEmerald,
          type: 2
        })

        nFirstEmerald.data.settlements.push({
          description: `Emerald Credit Bag ${node.points} by ${node.name} (50%)`,
          value: firstEmerald,
          type: 2
        })
      }

      if (myEmerald.length > 1) {
        const nSecondEmerald = myEmerald[1]
        const secondEmerld = emeraldPayment * 0.25
        node.settlements.push({
          description: `Emerald Credit Bag ${node.points} for ${nSecondEmerald.data.name} (25%)`,
          value: -secondEmerld,
          type: 2
        })

        nSecondEmerald.data.settlements.push({
          description: `Emerald Credit Bag ${node.points} by ${node.name} (25%)`,
          value: secondEmerld,
          type: 2
        })
      }
    }
    if (bags && bags.direct) {
      node.settlements.push({
        description: `Emerald Direct Bag ${node.points}`,
        value: Math.round((node.points * bags.direct.emeraldFactorTicket * 100)) / 100,
        type: 3
      })
    }

    // Validating if you have the minimal tickets
    if (bags) {
      // unmeet condition not having sold minimum tickets
      if ((node.level === 'D' && node.points < config.diamondTickets) && (node.downlines.length !== 6 || node.points === 0)) {
        const settlementDiamond = node.settlements.filter(d => d.type === 0 || d.type === 1).reduce((a, s) => a + s.value, 0)
        const upDFirstGeneration = settlementDiamond * config.unmeetCondition.firstGeneration
        const upDSecondGeneration = settlementDiamond * config.unmeetCondition.secondGeneration
        const upDThirdGeneration = settlementDiamond * config.unmeetCondition.thirdGeneration
        const myDiamondsUp = s.ancestors().filter(p => p.data.id !== node.id && p.data.level === 'D')

        if (myDiamondsUp.length === 0) {
          // send to bag
          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for Organization Bag`,
            value: -settlementDiamond,
            type: 4
          })
        } else if (myDiamondsUp.length < 2) {
          myDiamondsUp[0].data.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets by ${node.name}`,
            value: upDFirstGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for ${myDiamondsUp[0].data.name}`,
            value: -upDFirstGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for Organization Bag`,
            value: -(settlementDiamond - upDFirstGeneration),
            type: 4
          })
        } else if (myDiamondsUp.length < 3) {
          myDiamondsUp[0].data.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets by ${node.name}`,
            value: upDFirstGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for ${myDiamondsUp[0].data.name}`,
            value: -upDFirstGeneration,
            type: 1
          })

          myDiamondsUp[1].data.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets by ${node.name}`,
            value: upDSecondGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for ${myDiamondsUp[1].data.name}`,
            value: -upDSecondGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for Organization Bag`,
            value: -(settlementDiamond - upDFirstGeneration - upDSecondGeneration),
            type: 4
          })
        } else {
          myDiamondsUp[0].data.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets by ${node.name}`,
            value: upDFirstGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for ${myDiamondsUp[0].data.name}`,
            value: -upDFirstGeneration,
            type: 1
          })

          myDiamondsUp[1].data.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets by ${node.name}`,
            value: upDSecondGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for ${myDiamondsUp[1].data.name}`,
            value: -upDSecondGeneration,
            type: 1
          })

          myDiamondsUp[2].data.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets by ${node.name}`,
            value: upDThirdGeneration,
            type: 1
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.diamondTickets} tickets for ${myDiamondsUp[2].data.name}`,
            value: -upDThirdGeneration,
            type: 1
          })
        }
      }

      // Validate unmeet conditions emerald
      if (node.points < config.emeraldTickets && (node.downlines.length < 3 || node.points === 0)) {
        const settlementEmerald = (Math.round(node.settlements.filter(d => d.type === 2 || d.type === 3).reduce((a, s) => a + s.value, 0) * 100) / 100)
        const upEFirstGeneration = settlementEmerald * config.unmeetCondition.firstGeneration
        const upESecondGeneration = settlementEmerald * config.unmeetCondition.secondGeneration
        const upEThirdGeneration = settlementEmerald * config.unmeetCondition.thirdGeneration
        const myUp = s.ancestors().filter(p => p.data.id !== node.id)

        if (myUp.length === 0) {
          // send to bag
          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for Organization Bag`,
            value: -settlementEmerald,
            type: 4
          })
        } else if (myUp.length < 2) {
          myUp[0].data.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets by ${node.name}`,
            value: Math.round((upEFirstGeneration) * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for ${myUp[0].data.name}`,
            value: -Math.round(upEFirstGeneration * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for Organization Bag`,
            value: -Math.round((settlementEmerald - upEFirstGeneration) * 100) / 100,
            type: 4
          })
        } else if (myUp.length < 3) {
          myUp[0].data.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets by ${node.name}`,
            value: Math.round((upEFirstGeneration) * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for ${myUp[0].data.name}`,
            value: -Math.round(upEFirstGeneration * 100) / 100,
            type: 3
          })

          myUp[1].data.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets by ${node.name}`,
            value: Math.round((upESecondGeneration) * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for ${myUp[1].data.name}`,
            value: -Math.round(upESecondGeneration * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for Organization Bag`,
            value: -Math.round((settlementEmerald - upEFirstGeneration - upESecondGeneration) * 100) / 100,
            type: 4
          })
        } else {
          myUp[0].data.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets by ${node.name}`,
            value: Math.round((upEFirstGeneration) * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for ${myUp[0].data.name}`,
            value: -Math.round(upEFirstGeneration * 100) / 100,
            type: 3
          })

          myUp[1].data.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets by ${node.name}`,
            value: Math.round((upESecondGeneration) * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for ${myUp[1].data.name}`,
            value: -Math.round(upESecondGeneration * 100) / 100,
            type: 3
          })

          myUp[2].data.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets by ${node.name}`,
            value: Math.round((upEThirdGeneration) * 100) / 100,
            type: 3
          })

          node.settlements.push({
            description: `unmeet condition not having sold ${config.emeraldTickets} tickets for ${myUp[2].data.name}`,
            value: -Math.round(upEThirdGeneration * 100) / 100,
            type: 3
          })
        }
      }
    }
    node.totalSettlements = Math.round(node.settlements.reduce((a, s) => a + s.value, 0) * 100) / 100
  })
}

function eventReducer (state, action) {
  let currentAction = action.type
  if (currentAction === eventActions.EXECUTE_CURRENT_ACTION) {
    if (state.currentAction !== '') {
      currentAction = state.currentAction
    } else {
      return state
    }
  }
  switch (currentAction) {
    case eventActions.SET_CURRENT_ACTION:
      if (!state.firstAbo && action.payload === eventActions.ADD_ABO) {
        state.firstAbo = genereateNewAbo(state)
        state.struct = d3.hierarchy(state.firstAbo, d => d.downlines)
        calculateNodes(state.struct, state.calc.bags, state.conf.calc)
        state.lastVersion = generateId()
      }
      return {
        ...state,
        currentAction: action.payload,
        tools: {
          ...state.tools,
          open: false
        }
      }
    case eventActions.TOOLS_CLOSE:
      return {
        ...state,
        tools: {
          ...state.tools,
          open: false
        }
      }
    case eventActions.TOOLS_OPEN:
      return {
        ...state,
        tools: {
          ...state.tools,
          open: true
        }
      }
    case eventActions.REMOVE_ABO: {
      const selected = state.struct ? state.struct.find(n => n.data.id === action.payload) : undefined
      // we need to removed all childs and links
      if (selected && selected.parent) {
        selected.parent.data.downlines = selected.parent.data.downlines.filter(d => d.id !== selected.data.id)
      }
      state.struct = d3.hierarchy(state.firstAbo, d => d.downlines)
      calculateNodes(state.struct, state.calc.bags, state.conf.calc)
      return {
        ...state,
        lastVersion: generateId()
      }
    }
    case eventActions.ADD_ABO: {
      const selected = state.struct ? state.struct.find(n => n.data.id === action.payload) : undefined
      const newAbo = genereateNewAbo(state)

      if (selected) {
        selected.data.downlines.push(newAbo)
        selected.data.groupPoints += newAbo.points
      } else if (state.firstAbo !== null) {
        state.firstAbo.downlines.push(newAbo)
        state.firstAbo.groupPoints += newAbo.points
      } else {
        state.firstAbo = newAbo
      }

      state.struct = d3.hierarchy(state.firstAbo, d => d.downlines)
      calculateNodes(state.struct, state.calc.bags, state.conf.calc)
      return {
        ...state,
        lastVersion: generateId()
      }
    }
    case eventActions.EDIT_ABO: {
      const selected = state.struct ? state.struct.find(n => n.data.id === action.payload) : undefined
      if (!selected) {
        return state
      }
      return {
        ...state,
        edit: {
          open: true,
          currentId: action.payload,
          title: `Editando ${selected.data.shortName}`,
          data: {
            name: `${selected.data.name || ''}`,
            quantity: selected.data.points,
            level: `${selected.data.level}`
          }
        }
      }
    }
    case eventActions.EDIT_CANCEL:
      return {
        ...state,
        edit: {
          ...state.edit,
          open: false
        }
      }
    case eventActions.EDIT_SAVE: {
      const selected = state.struct ? state.struct.find(n => n.data.id === state.edit.currentId) : undefined
      if (selected) {
        selected.data.name = state.edit.data.name
        selected.data.points = parseInt(state.edit.data.quantity)
        selected.data.level = state.edit.data.level
        state.lastVersion = generateId()
        calculateNodes(state.struct, state.calc.bags, state.conf.calc)
      }
      return {
        ...state,
        edit: {
          ...state.edit,
          open: false
        }
      }
    }
    case eventActions.EDIT_CHANGE: {
      state.edit.data[action.payload.field] = action.payload.value
      return {
        ...state
      }
    }
    case eventActions.EVENT_CALC_BEGIN:
      return {
        ...state,
        calc: {
          ...state.calc,
          open: true,
          totalTickets: state.struct ? state.struct.data.groupPoints : 0,
          title: `Total Tickets: ${state.struct ? state.struct.data.groupPoints : 0}`
        }
      }
    case eventActions.EVENT_CALC_CHANGE: {
      state.calc[action.payload.field] = action.payload.value
      return {
        ...state
      }
    }
    case eventActions.EVENT_CALC_CANCEL:
      return {
        ...state,
        calc: {
          ...state.calc,
          tmpPdf: null,
          cst: null,
          open: false,
          result: null
        }
      }
    case eventActions.EVENT_CALC_APPLY: {
      // Calculate bags
      if (state.calc.totalTickets === 0) {
        return state
      }
      state.calc.bags = {
        credits: {
          total: state.calc.utilities * state.conf.calc.creditBag.percentaje
        },
        direct: {
          total: state.calc.utilities * state.conf.calc.directBag.percentaje
        }
      }

      state.calc.bags.credits.diamond = state.calc.bags.credits.total * state.conf.calc.creditBag.factor.diamond
      state.calc.bags.credits.diamondFactorTicket = state.calc.bags.credits.diamond / state.calc.totalTickets
      state.calc.bags.direct.diamond = state.calc.bags.direct.total * state.conf.calc.directBag.factor.diamond
      state.calc.bags.direct.diamondFactorTicket = state.calc.bags.direct.diamond / state.calc.totalTickets

      state.calc.bags.credits.emerald = state.calc.bags.credits.total * state.conf.calc.creditBag.factor.emerald
      state.calc.bags.credits.emeraldFactorTicket = state.calc.bags.credits.emerald / state.calc.totalTickets
      state.calc.bags.direct.emerald = state.calc.bags.direct.total * state.conf.calc.directBag.factor.emerald
      state.calc.bags.direct.emeraldFactorTicket = state.calc.bags.direct.emerald / state.calc.totalTickets

      calculateNodes(state.struct, state.calc.bags, state.conf.calc)
      const calcDescendants = state.struct.descendants()

      state.calc.result = {
        title: 'Liquidación',
        selectTitle: 'Liquidación',
        selectValue: '-1',
        selectValues: [{
          label: 'General',
          value: '-1'
        }, {
          label: 'Organizational Bag',
          value: '-2'
        }, ...calcDescendants.map(n => ({ label: n.data.name, value: n.data.id })).sort((a, b) => {
          const aName = a.label.toLowerCase()
          const bName = b.label.toLowerCase()
          if (aName < bName) { return -1 }
          if (aName > bName) { return 1 }
          return 0
        })],
        settlements: []
      }

      state.calc.result.general = [
        {
          description: 'Total Settlements',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type !== 4).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        },
        {
          description: 'Organization Bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 4).reduce((x, c) => x + (c.value * -1), 0), 0),
          type: -1
        },
        {
          description: 'Credit Diamond bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 0).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        },
        {
          description: 'Credit Emerald bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 2).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        },
        {
          description: 'Direct Diamond bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 1).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        },
        {
          description: 'Direct Emerald bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 3).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        },
        {
          description: 'Credit bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 0 || c.type === 2).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        },
        {
          description: 'Direct bag',
          value: calcDescendants.reduce((a, d) => a + d.data.settlements.filter(c => c.type === 1 || c.type === 3).reduce((x, c) => x + c.value, 0), 0),
          type: -1
        }
      ]

      state.calc.result.organizationBag = []
      calcDescendants.filter(d => d.data.settlements.filter(c => c.type === 4).length > 0).forEach(d => {
        d.data.settlements.filter(c => c.type === 4).forEach(s => {
          state.calc.result.organizationBag.push({
            description: `${s.description} by ${d.data.name}`,
            value: -s.value
          })
        })
      })
      state.calc.result.settlements = state.calc.result.general

      return {
        ...state
      }
    }
    case eventActions.PROCESSING_FILE_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          processing: true,
          dialogOpen: false
        }
      }
    case eventActions.CHANGE_FILE_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          file: action.payload
        }
      }
    case eventActions.CANCEL_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          dialogOpen: false,
          file: null,
          processing: false
        }
      }
    case eventActions.START_IMPORT:
      return {
        ...state,
        import: {
          ...state.import,
          dialogOpen: true
        }
      }
    case eventActions.LOAD_ABOS: {
      action.payload.forEach(p => {
        p.downlines = []
        p.settlements = []
      })

      const root = d3.stratify().id(d => d.id).parentId(d => d.parent)(action.payload)
      state.firstAbo = root.data
      state.struct = root
      state.struct.each(d => {
        d.data.downlines = d.children ? d.children.map(child => child.data) : []
        d.data.children = undefined
        delete d.data.children
      })
      calculateNodes(state.struct, state.calc.bags, state.conf.calc)
      return {
        ...state,
        lastVersion: generateId(),
        import: {
          ...state.import,
          processing: false,
          dialogOpen: false
        },
        tools: {
          ...state.tools,
          open: false
        },
        currentAction: ''
      }
    }
    case eventActions.CHANGE_RESULT_SELECTED: {
      state.pdfFormat = null
      if (action.payload === '-1') {
        state.calc.result.settlements = state.calc.result.general
        state.calc.result.selected = {
          data: {
            name: 'General'
          }
        }
      } else if (action.payload === '-2') {
        state.calc.result.settlements = state.calc.result.organizationBag
        state.calc.result.selected = {
          data: {
            name: 'Organización'
          }
        }
      } else {
        const selected = state.struct ? state.struct.find(n => n.data.id === action.payload) : undefined
        if (selected) {
          state.calc.result.settlements = selected.data.settlements
          state.calc.result.selected = selected
          state.pdfFormat = state.calc.tmpPdf.visit(state.calc.cst, state.calc)
        }
      }
      state.calc.result.selectValue = action.payload
      return {
        ...state
      }
    }
    case eventActions.EVENT_CALC_SET_PDF: {
      return {
        ...state,
        calc: {
          ...state.calc,
          tmpPdf: action.payload.interpreter,
          cst: action.payload.cst
        }
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const EventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(eventReducer, initialValues)
  const eventContextValue = {
    state, dispatch
  }
  return (
    <EventContext.Provider value={eventContextValue}>
      {children}
    </EventContext.Provider>
  )
}

EventProvider.propTypes = {
  children: PropTypes.object
}

const useEventProvider = () => {
  const context = useContext(EventContext)
  if (context === undefined) {
    throw new Error('useEventProvider must be used within a EventProvider')
  }
  return context
}

export {
  EventProvider,
  eventActions,
  useEventProvider
}
