import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { ccyFormat } from '../utils/format'

const borderColor = '#678BAE'
const stylesTable = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor,
    fontSize: 14
  },
  tableTitle: {
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#F0F0F0',
    color: '#FFFFFF',
    fontSize: 14
  },
  headerContainer: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    backgroundColor: '#5E5E5E0A',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    flexGrow: 1,
    fontSize: 14
  },
  headerDescription: {
    width: '75%',
    fontSize: 14,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2
  },
  headerAmount: {
    width: '25%',
    fontSize: 14,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: 14
  },
  description: {
    width: '75%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14
  },
  amount: {
    width: '25%',
    textAlign: 'right',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14
  }
})

function PDfSettlement ({ title, data, col1, col2, total, showTotal }) {
  const headerDescription = col1 ?? 'Descripción'
  const headerAmount = col2 ?? 'Valor'
  const headerTotal = total ?? 'Total'
  return (
    <View style={stylesTable.tableContainer}>
      {title && (
        <View style={stylesTable.tableTitle}>
          <Text>{title}</Text>
        </View>
      )}
      <View style={stylesTable.headerContainer} fixed>
        <Text style={stylesTable.headerDescription}>{ headerDescription }</Text>
        <Text style={stylesTable.headerAmount}>{ headerAmount }</Text>
      </View>
      {
        data.map((item, i) => (
          <View key={i} style={[stylesTable.row, { color: item.value <= 0 ? '#FF0000' : '#000000', backgroundColor: (i % 2 === 0 ? '#FFFFFF' : '#E3E5E8') }]}>
            <Text style={stylesTable.description}>{ item.description }</Text>
            <Text style={stylesTable.amount}>{ ccyFormat(item.value) }</Text>
          </View>
        ))
      }
      {
        showTotal === 1 && (
          <View style={stylesTable.headerContainer}>
            <Text style={[stylesTable.headerDescription, { textAlign: 'right' }]}> { headerTotal } </Text>
            <Text style={[stylesTable.headerAmount, { textAlign: 'right' }]}>{ ccyFormat(data.reduce((a, i) => a + i.value, 0)) }</Text>
          </View>
        )
      }
    </View>
  )
}

PDfSettlement.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  col1: PropTypes.string,
  col2: PropTypes.string,
  total: PropTypes.string,
  showTotal: PropTypes.number
}

export default PDfSettlement
