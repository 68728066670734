import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes
} from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { createContext, useContext, useReducer } from 'react'
import { CssBaseline } from '@mui/material'

let eventsTheme = createTheme({
  palette: {
    background: {
      default: '#f1f1f1'
    }
  }
})
eventsTheme = responsiveFontSizes(eventsTheme)
eventsTheme.components.MuiCssBaseline = {
  ...eventsTheme.components.MuiCssBaseline,
  styleOverrides: `
    html {
      background-color: ${eventsTheme.palette.background.default};
      height: 100%;
      line-height: 1.15;
    }
    body {
      background-color: ${eventsTheme.palette.background.default};
      height: 100%;
      font-size: 1rem;
      line-height: 1.15;
    }
    #root {
      height: 100%;
    }
  `
}

const EventThemeContext = createContext()

function eventThemeReducer (state, action) {
  switch (action.type) {
    case 'changeTheme': {
      const newTheme = createTheme(action.payload)
      return { currentTheme: newTheme }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const EventsThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(eventThemeReducer, { currentTheme: eventsTheme })
  const themeContextValue = {
    state, dispatch
  }
  return (
    <EventThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={state.currentTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </EventThemeContext.Provider>
  )
}
EventsThemeProvider.propTypes = {
  children: PropTypes.object
}

const useEventThemeProvider = () => {
  const context = useContext(EventThemeContext)
  if (context === undefined) {
    throw new Error('useEventThemeProvider must be used wwithin a EventThemeProvider')
  }
  return context
}

export {
  EventsThemeProvider,
  useEventThemeProvider
}
