import {
  AppBar,
  IconButton,
  InputBase,
  alpha,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ShareIcon from '@mui/icons-material/Share'
import InfoIcon from '@mui/icons-material/Info'
import React, { useEffect, useState } from 'react'
import CardComponent from './Components/CartComponent'
import { CartProvider } from './Contexts/cartProvider'
import { FeedProvider } from './Contexts/feedProviders'
import FeedComponent from './Components/FeedComponent'
import { EventProvider } from './Contexts/eventsProviders'
import EventComponent from './Components/EventComponent'
const PREFIX = 'App'

const classes = {
  title: `${PREFIX}-title`,
  container: `${PREFIX}-container`,
  menuButton: `${PREFIX}-menuButton`,
  inputRoot: `${PREFIX}-inputRoot`,
  list: `${PREFIX}-list`,
  footer: `${PREFIX}-footer`
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}))

const Container = styled('div')((
  {
    theme
  }
) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [`& .${classes.title}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },

  [`& .${classes.container}`]: {
    flex: '1 1 100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 2, 3)
    }
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2)
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit'
  },

  [`& .${classes.list}`]: {
    width: 250
  },

  [`& .${classes.footer}`]: {
    textAlign: 'center',
    backgroundColor: 'white',
    height: '4.3rem',
    borderTop: '1px solid #dbdbdb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '12rem'
    }
  }
}))

function App () {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [title, setTitle] = useState('Tools')
  const [searchPlaceHolder, setSearchPlaceHolder] = useState('Buscar...')
  const [valueSearch, setValueSearch] = useState('')
  const [valueSearchChild, setValueSearchChild] = useState('')
  const [valueSearchTimeOut, setValueSearchTimeOut] = useState(null)

  useEffect(() => {
    if (valueSearchTimeOut) {
      clearTimeout(valueSearchTimeOut)
    }
    setValueSearchTimeOut(
      setTimeout(() => {
        setValueSearchChild(valueSearch)
      }, 1000)
    )

    return () => clearTimeout(valueSearchTimeOut)
  }, [valueSearch])

  return (
    <Router>
      <Container>
        <AppBar position='static'>
          <Toolbar>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              onClick={() => setMenuIsOpen(!menuIsOpen)}
              size='large'
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap className={classes.title}>
              {title}
            </Typography>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={searchPlaceHolder}
                value={valueSearch}
                onChange={(e) => setValueSearch(e.target.value)}
              />
            </Search>
          </Toolbar>
        </AppBar>
        <Drawer open={menuIsOpen} onClose={() => setMenuIsOpen(!menuIsOpen)}>
          <div className={classes.list} onClick={() => setMenuIsOpen(!menuIsOpen)}>
            <List>
              <Link to='/portafolio'>
                <ListItem button>
                  <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                  <ListItemText primary='Portafolio' />
                </ListItem>
              </Link>
              <Link to='/honorarios'>
                <ListItem button>
                  <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                  <ListItemText primary='Honorarios' />
                </ListItem>
              </Link>
              <Link to='/eventos'>
                <ListItem button>
                  <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                  <ListItemText primary='Eventos' />
                </ListItem>
              </Link>
            </List>
            <Divider />
            <List>
              <ListItem button>
                <ListItemIcon><ShareIcon /></ListItemIcon>
                <ListItemText primary='Compartir' />
              </ListItem>
              <ListItem button>
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText primary='Acerca de' />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <div className={classes.container}>
          <Routes>
            <Route
              path='/' exact element={
                <div>Tools</div>
            }
            />
            <Route
              path='/portafolio' element={
                <CartProvider>
                  <CardComponent updateTitle={setTitle} updateSearchPlaceHolder={setSearchPlaceHolder} currentValueSearch={valueSearchChild} />
                </CartProvider>
            }
            />
            <Route
              path='/eventos' element={
                <EventProvider>
                  <EventComponent updateTitle={setTitle} updateSearchPlaceHolder={setSearchPlaceHolder} currentValueSearch={valueSearchChild} />
                </EventProvider>
            }
            />
            <Route
              path='/honorarios' element={
                <FeedProvider>
                  <FeedComponent updateTitle={setTitle} updateSearchPlaceHolder={setSearchPlaceHolder} currentValueSearch={valueSearchChild} />
                </FeedProvider>
            }
            />
            <Route
              path='*' element={
                <div>No encontrado</div>
              }
            />
          </Routes>
        </div>
        <footer className={classes.footer}>
          <a href='http://www.juancarvajal.com'>
            <img src='  /juancarvajalsas1.png' />
          </a>
        </footer>
      </Container>
    </Router>
  )
}

export default App
