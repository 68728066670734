import { createToken, Lexer, CstParser } from 'chevrotain'

// ----------------- Lexer -------------------------
const Identifier = createToken({ name: 'Identifier', pattern: /[a-zA-Z]\w*/ })

const LCurly = createToken({ name: 'LCurly', pattern: /{/ })
const RCurly = createToken({ name: 'RCurly', pattern: /}/ })

const LFormula = createToken({ name: 'LFormula', pattern: /!#/ })
const RFormula = createToken({ name: 'RFormula', pattern: /#!/ })

const StringLiteral = createToken({
  name: 'StringLiteral', pattern: /"(?:[^\\"]|\\(?:[bfnrtv"\\/]|u[0-9a-fA-F]{4}))*"/
})

const NumberLiteral = createToken({
  name: 'NumberLiteral', pattern: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/
})

const WhiteSpace = createToken({
  name: 'WhiteSpace',
  pattern: /\s+/,
  group: Lexer.SKIPPED
})

const AttributeKeyword = createToken({
  name: 'Attribute',
  pattern: /att/,
  longer_alt: Identifier
})

const BlockAttributeKeyword = createToken({
  name: 'BlockAttribute',
  pattern: /bAtt/,
  longer_alt: Identifier
})

const DocumentKeyword = createToken({
  name: 'Document',
  pattern: /document/,
  longer_alt: Identifier
})

const PageKeyword = createToken({
  name: 'Page',
  pattern: /page/,
  longer_alt: Identifier
})

const ImageKeyword = createToken({
  name: 'Image',
  pattern: /image/,
  longer_alt: Identifier
})

const TextKeyword = createToken({
  name: 'Text',
  pattern: /text/,
  longer_alt: Identifier
})

const ViewKeyword = createToken({
  name: 'View',
  pattern: /view/,
  longer_alt: Identifier
})

const MapKeyword = createToken({
  name: 'Map',
  pattern: /map/,
  longer_alt: Identifier
})

const SettlementKeyword = createToken({
  name: 'Settlement',
  pattern: /settlement/,
  longer_alt: Identifier
})

const TitleKeyword = createToken({
  name: 'Title',
  pattern: /_title/,
  longer_alt: Identifier
})

const NameKeyword = createToken({
  name: 'Name',
  pattern: /_name/,
  longer_alt: Identifier
})

const SBagKeyword = createToken({
  name: 'SBag',
  pattern: /_s_bag/,
  longer_alt: Identifier
})

const SGeneralKeyword = createToken({
  name: 'SGeneral',
  pattern: /_s_general/,
  longer_alt: Identifier
})

const SOrganizationKeyword = createToken({
  name: 'SOrganization',
  pattern: /_s_organization/,
  longer_alt: Identifier
})

const DslTokens = [
  WhiteSpace,
  // Keywords
  DocumentKeyword,
  PageKeyword,
  ViewKeyword,
  ImageKeyword,
  TextKeyword,
  AttributeKeyword,
  BlockAttributeKeyword,
  TitleKeyword,
  NameKeyword,
  SettlementKeyword,
  SBagKeyword,
  SGeneralKeyword,
  SOrganizationKeyword,
  MapKeyword,

  Identifier,
  StringLiteral,
  NumberLiteral,

  RCurly,
  LCurly,
  LFormula,
  RFormula
]

const DslLexer = new Lexer(DslTokens)

LCurly.LABEL = '\'{\''
RCurly.LABEL = '\'}\''
LFormula.LABEL = '\'!#\''
RFormula.LABEL = '\'#!\''

// ----------------- Parser -------------------------
class DslParser extends CstParser {
  constructor () {
    super(DslTokens)

    const $ = this

    $.RULE('document', () => {
      $.CONSUME(DocumentKeyword)
      $.CONSUME(LCurly)
      $.MANY(() => {
        $.OR([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.AT_LEAST_ONE(() => {
        $.SUBRULE($.page)
      })
      $.CONSUME(RCurly)
    })

    $.RULE('page', () => {
      $.CONSUME(PageKeyword)
      $.CONSUME(LCurly)
      $.MANY(() => {
        $.OR([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.MANY2(() => {
        $.SUBRULE($.items)
      })
      $.CONSUME(RCurly)
    })

    $.RULE('items', () => {
      $.OR([
        { ALT: () => $.SUBRULE($.view, { LABEL: 'item' }) },
        { ALT: () => $.SUBRULE($.image, { LABEL: 'item' }) },
        { ALT: () => $.SUBRULE($.text, { LABEL: 'item' }) },
        { ALT: () => $.SUBRULE($.settlement, { LABEL: 'item' }) },
        { ALT: () => $.SUBRULE($.map, { LABEL: 'item' }) }
      ])
    })

    $.RULE('view', () => {
      $.CONSUME(ViewKeyword)
      $.CONSUME(LCurly)
      $.MANY(() => {
        $.OR([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.MANY2(() => {
        $.SUBRULE($.items)
      })
      $.CONSUME(RCurly)
    })

    $.RULE('image', () => {
      $.CONSUME(ImageKeyword)
      $.CONSUME(LCurly)
      $.CONSUME(StringLiteral, { LABEL: 'src' })
      $.MANY(() => {
        $.OR([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.CONSUME(RCurly)
    })

    $.RULE('text', () => {
      $.CONSUME(TextKeyword)
      $.CONSUME(LCurly)
      $.OR([
        { ALT: () => $.CONSUME(StringLiteral) },
        { ALT: () => $.SUBRULE($.formula) }
      ])
      $.MANY(() => {
        $.OR1([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.CONSUME(RCurly)
    })

    $.RULE('map', () => {
      $.CONSUME(MapKeyword)
      $.CONSUME(LCurly)
      $.MANY(() => {
        $.OR1([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.CONSUME(RCurly)
    })

    $.RULE('settlement', () => {
      $.CONSUME(SettlementKeyword)
      $.CONSUME(LCurly)
      $.CONSUME(LFormula)
      $.OR([
        { ALT: () => $.CONSUME(SGeneralKeyword, { LABEL: 'general' }) },
        { ALT: () => $.CONSUME(SOrganizationKeyword, { LABEL: 'organization' }) },
        { ALT: () => $.CONSUME(SBagKeyword, { LABEL: 'bag' }) }
      ])
      $.CONSUME(RFormula)
      $.MANY(() => {
        $.OR1([
          { ALT: () => $.SUBRULE($.attribute) },
          { ALT: () => $.SUBRULE($.blockAttribute) }
        ])
      })
      $.CONSUME(RCurly)
    })

    $.RULE('attribute', () => {
      $.CONSUME(AttributeKeyword)
      $.CONSUME(Identifier)
      $.SUBRULE($.value)
    })

    $.RULE('blockAttribute', () => {
      $.CONSUME(BlockAttributeKeyword)
      $.CONSUME(Identifier, { LABEL: 'name' })
      $.CONSUME(LCurly)
      $.MANY(() => {
        $.SUBRULE($.attribute)
      })
      $.CONSUME(RCurly)
    })

    $.RULE('formula', () => {
      $.CONSUME(LFormula)
      $.OR([
        { ALT: () => $.CONSUME(TitleKeyword, { LABEL: 'title' }) },
        { ALT: () => $.CONSUME(NameKeyword, { LABEL: 'name' }) }
      ])
      $.CONSUME(RFormula)
    })

    $.RULE('value', () => {
      $.OR([
        { ALT: () => $.CONSUME(StringLiteral) },
        { ALT: () => $.CONSUME(NumberLiteral) }
      ])
    })

    this.performSelfAnalysis()
  }
}
const dlsParser = new DslParser([])

// ----------------- Interpreter -------------------------
const BaseCstVisitor = dlsParser.getBaseCstVisitorConstructor()

class DslInterpreter extends BaseCstVisitor {
  constructor () {
    super()

    this.validateVisitor()
  }

  document (ctx, value) {
    const jsonDocument = {
      tag: 'document'
    }

    if (ctx.attribute) {
      jsonDocument.props = jsonDocument.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonDocument.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonDocument.props = jsonDocument.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonDocument.props[attInfo.name] = attInfo.value
      })
    }

    jsonDocument.children = []
    ctx.page.forEach(p => {
      const page = this.visit(p, value)
      jsonDocument.children.push(page)
    })

    return jsonDocument
  }

  page (ctx, value) {
    const jsonPage = {
      tag: 'page'
    }

    if (ctx.attribute) {
      jsonPage.props = jsonPage.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonPage.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonPage.props = jsonPage.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonPage.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.items) {
      jsonPage.children = []
      ctx.items.forEach(item => {
        const itemValue = this.visit(item, value)
        jsonPage.children.push(itemValue)
      })
    }

    return jsonPage
  }

  items (ctx, value) {
    return this.visit(ctx.item, value)
  }

  view (ctx, value) {
    const jsonView = {
      tag: 'view'
    }

    if (ctx.attribute) {
      jsonView.props = jsonView.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonView.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonView.props = jsonView.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonView.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.items) {
      jsonView.children = []
      ctx.items.forEach(item => {
        const itemValue = this.visit(item, value)
        jsonView.children.push(itemValue)
      })
    }

    return jsonView
  }

  image (ctx, value) {
    const jsonImage = {
      tag: 'image',
      props: {
        src: ctx.src[0].image.replaceAll(/^"|"$/ig, '')
      }
    }

    if (ctx.attribute) {
      jsonImage.props = jsonImage.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonImage.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonImage.props = jsonImage.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonImage.props[attInfo.name] = attInfo.value
      })
    }

    return jsonImage
  }

  text (ctx, value) {
    const jsonText = {
      tag: 'text'
    }

    if (ctx.StringLiteral) {
      jsonText.children = ctx.StringLiteral[0].image.replaceAll(/^"|"$/ig, '')
    }

    if (ctx.formula) {
      jsonText.children = this.visit(ctx.formula, value)
    }

    if (ctx.attribute) {
      jsonText.props = jsonText.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonText.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonText.props = jsonText.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonText.props[attInfo.name] = attInfo.value
      })
    }

    return jsonText
  }

  settlement (ctx, value) {
    const jsonSettlement = {
      tag: 'pdfSettlement',
      props: {}
    }

    if (ctx.general) {
      jsonSettlement.props.data = value.result.general
    }

    if (ctx.organization) {
      jsonSettlement.props.data = value.result.organization
    }

    if (ctx.bag) {
      jsonSettlement.props.data = value.result.settlements
    }

    if (ctx.attribute) {
      jsonSettlement.props = jsonSettlement.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonSettlement.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonSettlement.props = jsonSettlement.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonSettlement.props[attInfo.name] = attInfo.value
      })
    }

    return jsonSettlement
  }

  attribute (ctx, value) {
    return {
      name: ctx.Identifier[0].image,
      value: this.visit(ctx.value, value)
    }
  }

  blockAttribute (ctx, value) {
    const block = {
      name: ctx.name[0].image,
      value: {}
    }

    if (ctx.attribute) {
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        block.value[attInfo.name] = attInfo.value
      })
    }
    return block
  }

  formula (ctx, value) {
    if (ctx.name) {
      return value.result.selected ? value.result.selected.data.name : 'No definido'
    }
    if (ctx.title) {
      return value.eventName ? value.eventName : 'No definido'
    }
  }

  value (ctx) {
    if (ctx.StringLiteral) {
      return ctx.StringLiteral[0].image.replaceAll(/^"|"$/ig, '')
    }
    if (ctx.NumberLiteral) {
      return parseInt(ctx.NumberLiteral[0].image)
    }
  }

  map (ctx, value) {
    const jsonMap = {
      tag: 'pdfMap',
      props: {}
    }

    jsonMap.props.root = value.result.selected

    if (ctx.attribute) {
      jsonMap.props = jsonMap.props || {}
      ctx.attribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonMap.props[attInfo.name] = attInfo.value
      })
    }

    if (ctx.blockAttribute) {
      jsonMap.props = jsonMap.props || {}
      ctx.blockAttribute.forEach((att) => {
        const attInfo = this.visit(att, value)
        jsonMap.props[attInfo.name] = attInfo.value
      })
    }

    return jsonMap
  }
}

const dslInterpreterInstance = new DslInterpreter()

export function getInterpreterDsl (input) {
  const dslLexerResult = DslLexer.tokenize(input)
  dlsParser.input = dslLexerResult.tokens

  const cst = dlsParser.document()

  return {
    cst,
    lexResult: dslLexerResult,
    parseErrors: dlsParser.errors,
    interpreter: dslInterpreterInstance
  }
}
