import React, { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { eventActions, useEventProvider } from '../Contexts/eventsProviders'
import * as d3 from 'd3'

import { Font } from '@react-pdf/renderer'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Select, MenuItem, InputLabel, Button, Dialog, OutlinedInput, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Backdrop, CircularProgress, Divider } from '@mui/material'
import ReportSettlementsPdf from './ReportSettlementsPdf'
import SummaryComponent from './SummaryComponent'
import { getInterpreterDsl } from '../utils/dsl/grammar'

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  '& > svg': {
    height: '100%',
    width: '100%'
  }
}))

const actions = [
  {
    icon: <AddOutlinedIcon />,
    name: 'New',
    handleClick: (state, dispatch) => {
      dispatch({ type: eventActions.SET_CURRENT_ACTION, payload: eventActions.ADD_ABO })
    }
  },
  {
    icon: <DeleteForeverOutlinedIcon />,
    name: 'Remove',
    handleClick: (state, dispatch) => {
      dispatch({ type: eventActions.SET_CURRENT_ACTION, payload: eventActions.REMOVE_ABO })
    }
  },
  {
    icon: <ModeEditOutlineOutlinedIcon />,
    name: 'Edit',
    handleClick: (state, dispatch) => {
      dispatch({ type: eventActions.SET_CURRENT_ACTION, payload: eventActions.EDIT_ABO })
    }
  },
  {
    icon: <CalculateOutlinedIcon />,
    name: 'Calculate',
    handleClick: (state, dispatch) => {
      dispatch({ type: eventActions.EVENT_CALC_BEGIN })
    }
  },
  {
    icon: <UploadFileOutlinedIcon />,
    name: 'Upload Structure',
    handleClick: (state, dispatch) => {
      dispatch({ type: eventActions.START_IMPORT })
    }
  },
  {
    icon: <FileDownloadOutlinedIcon />,
    name: 'Download Structure',
    handleClick: (state, dispatch) => {
      if (state.struct != null) {
        const sStruct = state.struct.descendants().map((i) => {
          return `${i.data.id},${i.parent ? i.parent.data.id : ''},"${i.data.level}","${i.data.name}", ${i.data.points}`
        }).join('\n')
        const file = new Blob([sStruct], { type: 'text/plain' })
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = 'structure.txt'
        document.body.appendChild(element)
        element.click()
        element.remove()
      }
    }
  }
]

function EventComponent ({ updateTitle, updateSearchPlaceHolder, currentValueSearch }) {
  const { state, dispatch } = useEventProvider()
  const [size, setSize] = useState({ width: 0, height: 0 })
  const svgRef = useRef(null)

  const handleCancelImport = (dispath) => {
    dispatch({ type: eventActions.CANCEL_IMPORT })
  }

  const handleFileChange = (dispatch, e) => {
    dispatch({ type: eventActions.CHANGE_FILE_IMPORT, payload: e.target.files[0] })
  }

  const handleSelectResultChange = (dispatch, e) => {
    dispatch({ type: eventActions.CHANGE_RESULT_SELECTED, payload: e.target.value })
  }

  const handleFormatPdfFile = (e) => {
    e.preventDefault()

    // we need to parse pdf file
    const reader = new FileReader()
    reader.onload = (eReader) => {
      const dataString = eReader.target.result
      const result = getInterpreterDsl(dataString)
      if (result.parseErrors && result.parseErrors.length > 0) {
        alert('Archivo no valido')
        console.log(result.parseErrors[0].message)
        return
      }

      // time to set visitor, cst in state to evaluate in every calc
      dispatch({ type: eventActions.EVENT_CALC_SET_PDF, payload: { cst: result.cst, interpreter: result.interpreter } })
    }

    reader.readAsText(e.target.files[0])
  }

  const handleImportFile = (e) => {
    e.preventDefault()
    if (!state.import.file) {
      // TODO: SHow missing file
      return
    }
    dispatch({ type: eventActions.PROCESSING_FILE_IMPORT })
    const reader = new FileReader()
    reader.onload = (eReader) => {
      const dataString = eReader.target.result
      // TODO: move this into a function lib
      const dataStringLines = dataString.split(/\r\n|\n/)

      // headers is in third line
      // const headers = dataStringLines[2].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
      const abos = []
      for (let i = 0; i < dataStringLines.length; i++) {
        const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
        abos.push({
          parent: i === 0 ? null : row[1].replace(/^"|"$/g, ''),
          id: row[0].replace(/^"|"$/g, ''),
          points: parseInt(row[4].replace(/^"|,|"$/g, '')),
          name: row[3].replace(/^"|"$/g, ''),
          level: row[2].replace(/^"|"$/g, '')
        })
      }
      dispatch({ type: eventActions.LOAD_ABOS, payload: abos })
    }

    reader.readAsText(state.import.file)
  }

  const handleClose = () => {
    dispatch({ type: eventActions.TOOLS_CLOSE })
  }
  const handleOpen = () => {
    dispatch({ type: eventActions.TOOLS_OPEN })
  }

  const handleCircleClick = (c) => {
    console.log(c.data()[0].data.name)
    console.log(c.data()[0].data.shortName)
    dispatch({ type: eventActions.EXECUTE_CURRENT_ACTION, payload: c.data()[0].data.id })
  }

  const handleEditCancel = (d) => {
    d({ type: eventActions.EDIT_CANCEL })
  }

  const handleEditSave = (d) => {
    d({ type: eventActions.EDIT_SAVE })
  }

  const handleEditChange = (d, field, value) => {
    d({ type: eventActions.EDIT_CHANGE, payload: { field, value } })
  }

  const handleCalcCancel = (d) => {
    d({ type: eventActions.EVENT_CALC_CANCEL })
  }

  const handleCalcChange = (d, field, value) => {
    d({ type: eventActions.EVENT_CALC_CHANGE, payload: { field, value } })
  }

  const handleCalcSave = (d) => {
    d({ type: eventActions.EVENT_CALC_APPLY })
  }

  useEffect(() => {
    updateTitle('Eventos')
    if (svgRef.current != null) {
      setSize(svgRef.current.parentNode.getBoundingClientRect())
      d3.select(svgRef.current).append('g')
    }
  }, [])

  useEffect(() => {
    const svgContainer = d3.select(svgRef.current)
      .attr('viewBox', [-size.width / 2, -size.height / 2, size.width, size.height])
      .attr('preserveAspectRatio', 'xMidYMid meet')
    const svgEl = svgContainer.select('g')
    svgEl.selectAll('*').remove()
    const zoom = d3.zoom().on('zoom', function (event) {
      const transform = event.transform
      svgEl.attr('transform', transform)
    })

    const lineCenter = svgEl.append('g')
    lineCenter.append('line')
      .attr('x1', -size.width * 2)
      .attr('x2', size.width * 2)
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke-width', '2')
      .attr('stroke', 'black')

    lineCenter.append('line')
      .attr('y1', -size.height * 2)
      .attr('y2', size.height * 2)
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('stroke-dasharray', '5, 5')
      .attr('stroke-width', '2')
      .attr('stroke', 'black')

    svgContainer.call(zoom)

    if (state.firstAbo) {
      const root = state.struct
      const dx = 120
      const dy = 120 // size.width / (root.height + 1)
      d3.tree().nodeSize([dx, dy])(root)

      const nodes = root.descendants()

      svgEl.append('g')
        .attr('fill', 'none')
        .attr('stroke', '#555')
        .attr('stroke-opacity', 0.4)
        .attr('stroke-width', 1.5)
        .selectAll('path')
        .data(root.links())
        .join('path')
        .attr('d', d3.linkVertical()
          .x(d => d.x)
          .y(d => d.y))

      svgEl.append('g')
        .selectAll('circle')
        .data(nodes)
        .join('circle')
        .attr('stroke', 'black')
        .attr('stroke-width', '3')
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('fill', d => d.data.level === 'D' ? '#b9f2ff' : '#50C878')
        .attr('r', 40)

      svgEl.append('g')
        .style('font-size', '0.6rem')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .text(d => `${d.data.shortName}`)
        .attr('x', d => d.x)
        .attr('y', d => d.y)
        .attr('text-anchor', 'middle')
        .style('fill', d => d.data.level === 'D' ? 'black' : 'white')

      svgEl.append('g')
        .style('font-size', '0.6rem')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .text(d => `${d.data.points}`)
        .attr('x', d => d.x)
        .attr('y', d => d.y + 10)
        .attr('text-anchor', 'middle')
        .style('fill', d => d.data.level === 'D' ? 'black' : 'white')

      svgEl.append('g')
        .style('fill', 'transparent')
        .selectAll('circle')
        .data(nodes)
        .join('circle')
        .attr('r', 45)
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .on('click', function () {
          handleCircleClick(d3.select(this), state, dispatch)
        })
    }
  }, [svgRef.current, state.lastVersion])
  return (
    <Container>
      <svg ref={svgRef} />
      <SpeedDial
        ariaLabel='Eventos Actions'
        icon={<SpeedDialIcon icon={<BuildOutlinedIcon />} />}
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        open={state.tools.open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {actions.map((a, i) => (
          <SpeedDialAction key={i} icon={a.icon} tooltipTitle={a.name} onClick={() => a.handleClick(state, dispatch)} />
        ))}
      </SpeedDial>
      <Dialog open={state.edit.open} onClose={() => handleEditCancel(dispatch)} fullScreen>
        <DialogTitle>{state.edit.title}</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            type='text'
            label='Nombres'
            value={state.edit.data.name}
            onChange={(e) => handleEditChange(dispatch, 'name', e.target.value)}
            fullWidth
          />
          <TextField
            margin='dense'
            type='number'
            label='Cantidad'
            value={state.edit.data.quantity}
            onChange={(e) => handleEditChange(dispatch, 'quantity', e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <FormLabel>Nivel</FormLabel>
            <RadioGroup row value={state.edit.data.level} onChange={(e) => handleEditChange(dispatch, 'level', e.target.value)}>
              <FormControlLabel value='D' label='Diamante' control={<Radio />} />
              <FormControlLabel value='E' label='Esmeralda' control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleEditCancel(dispatch)}>Cancelar</Button>
          <Button onClick={() => handleEditSave(dispatch)}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={state.calc.open} scroll='paper' onClose={() => handleCalcCancel(dispatch)} fullScreen>
        <DialogTitle>{state.calc.title}</DialogTitle>
        <DialogContent dividers>
          <TextField
            margin='dense'
            type='text'
            label='Nombre Liquidación'
            value={state.calc.eventName}
            onChange={(e) => handleCalcChange(dispatch, 'eventName', e.target.value)}
            fullWidth
          />
          <TextField
            margin='dense'
            type='number'
            label='Utilidades'
            value={state.calc.utilities}
            onChange={(e) => handleCalcChange(dispatch, 'utilities', e.target.value)}
            fullWidth
          />

          <TextField
            margin='dense'
            label='Formato pdf'
            onChange={handleFormatPdfFile}
            type='file'
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: '.txt' }}
          />

          {state.calc.result &&
            <>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel id='lblResult'>{state.calc.result.selectTitle}</InputLabel>
                <Select labelId='lblResult' label={state.calc.result.selectTitle} value={state.calc.result.selectValue} onChange={(e) => handleSelectResultChange(dispatch, e)}>
                  {state.calc.result.selectValues.map((o, i) => (
                    <MenuItem key={i} value={o.value}>{o.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <SummaryComponent data={state.calc.result.settlements} showTotal={state.calc.result.selectValue !== '-1' && state.calc.result.selectValue !== '-2'} />
              {state.calc.result.selected &&
                <ReportSettlementsPdf format={state.pdfFormat} fileName={state.calc.result.selected.data.name}/>
              }
            </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCalcCancel(dispatch)}>Cancelar</Button>
          <Button disabled={state.calc.tmpPdf == null} onClick={() => handleCalcSave(dispatch)}>Calcular</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={state.import.dialogOpen} onClose={() => handleCancelImport(dispatch)}>
        <DialogTitle>Importar archivo de Eventos txt</DialogTitle>
        <DialogContent>
          <form>
            <FormControl fullWidth>
              <OutlinedInput onChange={(e) => handleFileChange(dispatch, e)} type='file' placeholder='selecciona el archivo' inputProps={{ accept: '.txt' }} />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelImport(dispatch)}>Cancelar</Button>
          <Button onClick={handleImportFile}>Importar</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={state.import.processing} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Container>
  )
}

EventComponent.propTypes = {
  updateTitle: PropTypes.func.isRequired,
  updateSearchPlaceHolder: PropTypes.func.isRequired,
  currentValueSearch: PropTypes.string.isRequired
}
export default EventComponent
