import React from 'react'
import { Image, Page, Text, View, Document } from '@react-pdf/renderer'
import PDfSettlement from '../Components/PDFSettlement'
import PDFSvg from '../Components/PDFSvg'

const KeysToComponentMap = {
  page: Page,
  image: Image,
  text: Text,
  view: View,
  document: Document,
  pdfSettlement: PDfSettlement,
  pdfMap: PDFSvg
}

export function RenderDynamicComponent (data, key = null) {
  if (typeof KeysToComponentMap[data.tag] !== 'undefined') {
    if (!data.props) {
      data.props = {}
    }
    data.props.key = key
    return React.createElement(
      KeysToComponentMap[data.tag],
      data.props,
      data.children && (typeof data.children === 'string' ? data.children : data.children.map((c, i) => RenderDynamicComponent(c, i)))
    )
  }

  return (
    <Text>Componente {data.tag} no se ha mapeado aun</Text>
  )
}
